import React, { Component } from 'react'
import './AccessRole.css'


class AccessRole extends Component{
    render()
    {
        return(
            <h1>AccessRole</h1>
        )
    }
}

export default AccessRole