import img1 from './img4.jpg'
import img2 from './img3.jpg'
import img3 from './img5.jpg'

export const Images=[
    {
        url:img1
    },
    {
        url:img2
    },
    {
        url:img3
    }
]