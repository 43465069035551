import React, { Component } from 'react'
import './Quiz.css'

class Quiz extends Component{
    render()
    {
        return(
            <h1>Quiz</h1>
        )
    }
}

export default  Quiz