import React, { useState, useEffect } from "react";

const StudentCompletedCourseTable = () => {
  const [students, setStudents] = useState([]);
  useEffect(() => {
    console.log("anaakjjkajksjs");
    fetch(
      "https://beresearcherapi-production.up.railway.app/api/get-students-completed-course/5f8ac2936e9334246ba98438"
    )
      .then((res) => res.json())
      .then((res) => {
        setStudents(res.students);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <h3>Student successfully completed the course, Research Methodology:</h3>
      <table border="0" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Id</th>
            <th>Department</th>
            <th>Institute</th>
          </tr>
        </thead>
        <tbody>
          {students.length > 0
            ? students.map((student) => (
                <tr>
                  <td>
                    {student.firstName} {student.lastName}
                  </td>
                  <td>{student.id}</td>
                  <td>{student.dept}</td>
                  <td>{student.institution}</td>
                </tr>
              ))
            : ""}
          {/* {this.props.studentList.map(( {enrolledCourses,firstName,lastName, institution, email }) => {
              
              i++;
              return (
                
                <tr key={i}>
                  <td>{firstName}{" "}{lastName}</td>
                  <td>{institution}</td>
                  <td><a href={`mailto:${email}`}>{email}</a></td>
                  <td>Research Methodology</td>
                  <td>
                    <CircularProgressbar
                      value={Math.floor((enrolledCourses[0].completedItem
                        /this.props.totalItem)*100)}
                      text={`${Math.floor((enrolledCourses[0].completedItem
                        /this.props.totalItem)*100)}%`}
                      className={"size"}
                    />
                  </td>
                </tr>
              );
            })} */}
        </tbody>
      </table>
    </>
  );
};

export default StudentCompletedCourseTable;
