import React, { Component } from 'react'
import './Mailbox.css'

class Mailbox extends Component{
    render()
    {
        return(
            <h1>Mailbox</h1>
        )
    }
}

export default Mailbox